import type { GridsItemProps } from './grid';
import { gridToStyles } from './grid';
import type { ResponsivePadding } from './padding';
import { paddingToStyles } from './padding';
import type { Sprinkles } from './sprinkles.css';
import { classFilter } from './utilities';

import { sprinkles } from './sprinkles.css';

export type StyleProps = Partial<
  Sprinkles & {
    padding: ResponsivePadding;
    gridItem: GridsItemProps;
  }
>;

export const getStyles = ({ padding, gridItem, ...otherProps }: StyleProps) => {
  return classFilter([
    otherProps && sprinkles(otherProps),
    padding && paddingToStyles(padding),
    gridItem && gridToStyles(gridItem),
  ]);
};
