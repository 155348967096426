import type {
  AllHTMLAttributes,
  CSSProperties,
  ElementType,
  ForwardedRef,
  ReactNode,
} from 'react';
import { forwardRef } from 'react';
import type { SpringValue } from '@react-spring/web';

import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import type { Display, GetBoxStyleProps } from './get-box-styles';
import { getBoxStyles } from './get-box-styles';

// Redeclare forwardRef based on https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
// but with added generics for element type
declare module 'react' {
  function forwardRef<Element = HTMLElement>(
    render: (props: BoxProps<Element>, ref: Ref<Element>) => ReactNode,
  ): <Element>(props: BoxProps<Element> & RefAttributes<Element>) => ReactNode;
}

export type BoxProps<Element = HTMLElement> = GetBoxStyleProps &
  Pick<
    AllHTMLAttributes<Element>,
    /** carefully add any html attributes we might use here when needed */
    | 'disabled'
    | 'onClick'
    | 'onFocus'
    | 'onBlur'
    | 'onChange'
    | 'onSubmit'
    | 'htmlFor'
    | 'id'
    | 'method'
    | 'name'
    | 'role'
    | 'className'
    | 'hidden'
    | 'checked'
    | 'type'
    | 'value'
    | 'defaultValue'
    | 'title'
    | 'placeholder'
    | 'autoComplete'
    | 'type'
    | 'target'
    | 'href'
    | 'download'
    | 'readOnly'
  > & {
    Component?: ElementType;
    style?: Record<string, SpringValue> | CSSProperties;
    children?: ReactNode;
  } & (
    | {
        /** special case to be used for NavLink components */
        to?: string;
        preventScrollReset?: boolean;
        prefetch?: 'none' | 'intent' | 'render' | 'viewport';
      }
    | {
        to?: never;
        preventScrollReset?: never;
        prefetch?: never;
      }
  );

export const filterDisplayProps = ({
  variant,
  alignContent,
  stack,
}: Pick<Display, 'variant' | 'alignContent' | 'stack'>) =>
  variant === 'initial'
    ? {
        variant,
      }
    : variant === 'grid'
      ? {
          variant,
          alignContent,
        }
      : ({
          variant,
          alignContent,
          stack,
        } satisfies Display);

/**
 * Do not use the BoxWithoutForwardRef export in the platform, use the Box export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const BoxWithoutForwardRef = <Element,>(
  {
    Component = 'div',
    background,
    color,
    children,
    stack,
    variant = 'flex',
    spacing,
    padding,
    alignContent,
    touchArea,
    rounded,
    expand,
    style, //this is meant to be used by react-spring for animations, not general styling.
    size,
    constrain,
    border,
    gridItem,
    className,
    hide,
    opacity,
    ...restProps
  }: BoxProps<Element>,
  ref: ForwardedRef<Element>,
) => {
  return (
    <Component
      style={style}
      {...restProps}
      className={classFilter([
        getBoxStyles({
          background,
          border,
          color,
          padding,
          spacing,
          rounded,
          expand,
          touchArea,
          size,
          constrain,
          gridItem,
          hide,
          opacity,
          ...filterDisplayProps({ variant, alignContent, stack }),
        }),
        className,
      ])}
      ref={ref}
    >
      {children}
    </Component>
  );
};

// Manually reset the displayName in the component tree.
BoxWithoutForwardRef.displayName = 'Box';

export const Box = forwardRef(BoxWithoutForwardRef);
