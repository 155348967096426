import type { GridEnd, GridStart, ResponsiveProps } from './types';
import { convertComplexPropsToSprinkles } from './utilities';

import { sprinkleGridItem } from './sprinkles.css';

type GridItem = {
  columnStart?: GridStart;
  columnEnd?: GridEnd;
  rowStart?: GridStart;
  rowEnd?: GridEnd;
};

export type GridsItemProps = GridItem | ResponsiveProps<GridItem>;

export const gridToStyles = (gridItem: GridsItemProps) =>
  convertComplexPropsToSprinkles(gridItem, sprinkleGridItem);
