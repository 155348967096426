import type { themeVars } from '@shieldpay/theme-provider-ui/theme/contract';
import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import * as styles from './text.css';

export interface UseTextStyleProps {
  transform?: keyof typeof styles.textTransform | false;
  truncate?: boolean;
  variant?: keyof typeof themeVars.typography.variant | 'inherit';
  align?: keyof typeof styles.align;
}

export const textVariantToStyles = (variant = 'body150') =>
  variant === 'inherit' ? styles.inherit : styles.variant[variant];

/**
 * return either all text styles including variant,
 * or just the variant (useful in input fields)
 */
export const getTextStyles = ({
  align,
  transform = false,
  variant = 'body150',
  truncate = false,
}: UseTextStyleProps) =>
  classFilter([
    styles.text,
    transform && styles.textTransform[transform],
    truncate && styles.truncate,
    textVariantToStyles(variant),
    align && styles.align[align],
  ]);
