import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import type { BoxProps } from '../box/box';
import { Box } from '../box/box';

import type { UseTextStyleProps } from './text-styles';
import { getTextStyles } from './text-styles';

type ParagraphOrComponent =
  | {
      Component: BoxProps['Component'];
      paragraph?: never;
    }
  | {
      Component?: never;
      paragraph?: boolean;
    };

export type TextProps = Pick<
  BoxProps,
  | 'children'
  | 'className'
  | 'hidden'
  | 'padding'
  | 'color'
  | 'htmlFor'
  | 'id'
  | 'expand'
  | 'role'
> &
  UseTextStyleProps &
  ParagraphOrComponent;

export const Text = ({
  children,
  Component,
  variant,
  transform = false,
  hidden = false,
  paragraph = false,
  truncate = false,
  padding = 'none',
  align,
  color = 'neutral500',
  className,
  ...boxProps
}: TextProps) => (
  <Box
    variant="initial"
    Component={Component || (paragraph ? 'p' : 'span')}
    className={classFilter([
      getTextStyles({
        variant,
        truncate,
        transform,
        align,
      }),
      className,
    ])}
    constrain={truncate ? 'clipX' : undefined}
    color={color}
    hidden={hidden}
    padding={padding}
    {...boxProps}
  >
    {children}
  </Box>
);
