import { transformValues } from '@shieldpay/utility-functions-ui/transform-values';

import type { Breakpoint } from './breakpoints';
import type {
  Padding,
  PaddingX,
  PaddingY,
  ResponsiveProps,
  Spacing,
} from './types';
import { convertComplexPropsToSprinkles, isResponsive } from './utilities';

import { sprinklePadding } from './sprinkles.css';

const paddingKey = ['x', 'y', 'left', 'right', 'top', 'bottom'] as const;

export type ResponsivePadding = Padding | ResponsiveProps<Padding>;

const isAllAroundPadding = (
  padding: Spacing | ResponsivePadding,
): padding is Spacing | Record<Breakpoint, Spacing> =>
  typeof padding === 'string' ||
  (isResponsive(padding) &&
    Object.values(padding).every((val) => typeof val === 'string'));

const isPlainPaddingObject = (
  padding: Spacing | ResponsivePadding,
): padding is PaddingX & PaddingY =>
  Object.keys(padding).every((key) =>
    paddingKey.map((key) => key.toString()).includes(key),
  );

export const paddingToStyles = (padding: Spacing | ResponsivePadding) => {
  if (isAllAroundPadding(padding)) {
    // plain padding only e.g.
    //   Spacing                  Record<Breakpoint, Spacing>
    //    padding='basePos1'      padding={ { xs: 'base', m: 'basePos1' } }
    return sprinklePadding({ padding });
  }

  if (isPlainPaddingObject(padding)) {
    // (PaddingX & PaddingY)
    // padding={ { x: 'base', top: 'basePos1' } }
    return sprinklePadding(padding);
  }

  // responsive complex padding
  // Record<Breakpoint, (PaddingX & PaddingY)>
  // padding={{
  //   xs: 'base',
  //   m: { right: 'base' },
  //   xl: { x: 'baseNeg1', bottom: 'base', },
  // }}
  return convertComplexPropsToSprinkles(
    transformValues(padding, (config) =>
      typeof config === 'string' ? { padding: config } : config,
    ),
    sprinklePadding,
  );
};
