export const breakpointValues = {
  xs: 320,
  s: 576,
  m: 768,
  l: 992,
  xl: 1366,
  xxl: 1680,
} as const;

export const breakpointKeys = Object.keys(breakpointValues) as Breakpoint[];

export const breakpoints = Object.fromEntries(
  Object.entries(breakpointValues).map(([key, value]) => [key, `${value}px`]),
) as Record<Breakpoint, string>;

export type Breakpoint = keyof typeof breakpointValues;
