import { breakpointKeys } from '@shieldpay/theme-provider-ui/breakpoints';
import type { StyleProps } from '@shieldpay/theme-provider-ui/styles';
import { getStyles } from '@shieldpay/theme-provider-ui/styles';
import type { ResponsiveProps } from '@shieldpay/theme-provider-ui/theme/types';
import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import * as styles from './box.css';

/**
 * Align items inside the box according to { x: X, y: Y }, e.g. { x: 'left', y: 'top' }
 * will align items to the left on the X axis and top of the Y axis.
 *
 * `full` will spread items along the axis with space between them.
 */
interface AlignContent {
  x?: keyof typeof styles.stackXAlignment.row;
  y?: keyof typeof styles.stackYAlignment.row;
}

export type Display =
  | {
      // this variant is mainly meant for elements like <p /> or <span /> when we want to keep as block or inline.
      variant: 'initial';
      alignContent?: never;
      stack?: never;
    }
  | {
      variant?: 'flex' | 'inline-flex';
      /**
       * The direction of items within a (flex) box.
       */
      stack?: 'column' | 'row';
      alignContent?: AlignContent;
    }
  | {
      variant: 'grid';
      stack?: never;
      alignContent?: AlignContent;
    };

export type GetBoxStyleProps = Display &
  Partial<
    Pick<
      StyleProps,
      'background' | 'color' | 'spacing' | 'border' | 'gridItem' | 'padding'
    >
  > & {
    /**
     * Gives box a rounded appearance
     */
    rounded?: boolean;
    /**
     * Give box a 'touch area' that expands beyond the box dimensions
     * without affecting layout.
     */
    touchArea?: boolean;
    /**
     * Allow the box to fill available space of the container
     */
    expand?: boolean;
    /**
     * Handle showing contents outside the box;
     */
    constrain?: keyof typeof styles.constrain;
    /**
     * Control which breakpoints hide the box
     */
    hide?: ResponsiveProps<boolean>;
    /**
     * Set min/max width/heights based on theme tokens
     */
    size?: Pick<
      StyleProps,
      'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight'
    >;
    opacity?: keyof typeof styles.opacity;
  };

const convertHidePropsToClassNames = (hide: ResponsiveProps<boolean>) => {
  const classNames = [];
  let prevHide = false;

  for (const breakpoint of breakpointKeys) {
    if (hide[breakpoint] ?? prevHide) {
      classNames.push(styles.hide[breakpoint]);
      prevHide = true;
      continue;
    }
    prevHide = false;
  }

  return classNames.join(' ');
};

export const getBoxStyles = ({
  variant = 'flex',
  stack = 'column',
  alignContent,
  spacing,
  rounded = false,
  touchArea = false,
  expand = false,
  color,
  background,
  size,
  constrain,
  border,
  hide,
  gridItem,
  padding,
  opacity,
}: GetBoxStyleProps) => {
  const alignStackContext = variant === 'flex' ? stack : 'row';

  return classFilter([
    // we want things like display none to be applied later
    variant !== 'initial' && [
      styles.variant[variant],
      alignContent?.x &&
        styles.stackXAlignment[alignStackContext][alignContent.x],
      alignContent?.y &&
        styles.stackYAlignment[alignStackContext][alignContent.y],
    ],
    getStyles({
      background,
      border,
      color,
      spacing,
      gridItem,
      padding,
      stack: variant === 'flex' ? stack : undefined,
      ...size,
    }),
    touchArea && styles.touchArea,
    rounded && styles.rounded,
    expand && styles.expand,
    constrain && styles.constrain[constrain],
    hide && convertHidePropsToClassNames(hide),
    opacity && styles.opacity[opacity],
  ]);
};
