/**
 * Iterates over every entry in an object, allowing a transformation on each entry.
 * There are optional ObjectIn and ObjectOut generics for more complex scenarios.
 */
export function transformValues<
  Input extends Record<string, unknown>,
  OutputValue,
>(
  input: Input,
  callback: (value: Input[keyof Input], key: keyof Input) => OutputValue,
) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {};

  for (const key in input) {
    result[key] = callback(input[key], key);
  }

  return result satisfies Record<keyof Input, OutputValue>;
}
