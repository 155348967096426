import type { style, StyleRule } from '@vanilla-extract/css';
import { globalStyle } from '@vanilla-extract/css';
import { calc } from '@vanilla-extract/css-utils';

import type { Breakpoint } from './breakpoints';
import { breakpoints } from './breakpoints';
import type { Responsive, ResponsiveProps } from './types';

import { themeVars } from './contract.css';

type Style = Parameters<typeof style>[0];

export const classFilter = (values: Array<unknown>) =>
  values
    .flat()
    .filter((value) => {
      if (Array.isArray(value)) {
        throw new Error(`oops, wrong value im ${value}`);
      }

      return typeof value === 'string' && value.length > 0;
    })
    .join(' ');

export const createSvgIconChild = (parentClass: string) => {
  globalStyle(`${parentClass} *`, {
    fill: 'currentColor', // inherit the icon color from parent
  });
  globalStyle(`${parentClass} [stroke]`, {
    /**
     * We remove the fill on current color when an element in an svg has a stroke attribute,
     * and apply currentColor on the stroke instead. This assume that all icons will be
     * one colour and no element will have a mix of stroke and fill.
     */
    fill: 'revert',
    stroke: 'currentColor',
  });
};

export const focusRingStyle = {
  style: {
    default: {
      outline: `${themeVars.spacing.baseNeg6} solid ${themeVars.palette.primary400}`,
    },
    inset: {
      outline: `${themeVars.spacing.baseNeg7} solid ${themeVars.palette.primary400}`,
      outlineOffset: calc.negate(themeVars.spacing.baseNeg7),
    },
  },
  transition: `outline ${themeVars.transitions.standard}`,
};

/**
 * Generate a set of responsive styles using breakpoint values from themeVars.
 * smallest breakpoint is set without a media query
 */
export const responsiveStyle = (styles: ResponsiveProps<StyleRule>) => {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = { '@media': {} };

  for (const breakpoint in styles) {
    const style = styles[breakpoint as Breakpoint] || {};

    if (breakpoint === 'xs') {
      Object.assign(result, style);
    } else {
      const pixels = breakpoints[breakpoint as Breakpoint];

      result['@media'][`screen and (min-width: ${pixels})`] = style;
    }
  }

  return result satisfies Style;
};

export const transparentize = (color: string, amount: number) =>
  `color-mix(in srgb, ${color}, transparent ${100 - amount}%)`;

/**
 * typeguard for responsive values
 */
export const isResponsive = <Value>(
  value: Value | Partial<Responsive<Value>>,
): value is Partial<Responsive<Value>> =>
  typeof value !== 'string' &&
  Object.keys(value as Responsive<Value>).some((key) => key in breakpoints);

/**
 * Converts our complex responsive props to their vanilla-extract classnames
 * 
 * e.g. convertComplexPropsToSprinkle({padding: {
  //   xs: 'base',
  //   m: { right: 'base' },
  //   xl: { x: 'baseNeg1', bottom: 'base', },
  // })
 */
export const convertComplexPropsToSprinkles = <
  Config extends Record<string, unknown>,
>(
  config: Config | ResponsiveProps<Config>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: any,
) => {
  if (!isResponsive(config)) {
    return callback(config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {};

  for (const breakpoint in config) {
    const innerConfig = config[breakpoint as Breakpoint];

    for (const property in innerConfig) {
      (result[property] = result[property] || {})[breakpoint] =
        innerConfig[property as keyof Config];
    }
  }

  return callback(result);
};

/**
 * Get the current scoped theme value of a css variable
 */
export const getThemedValue = (
  themeVar: string,
  scope = globalThis.document?.body,
) => {
  return scope
    ? Number(
        window
          .getComputedStyle(scope)
          .getPropertyValue(themeVar.split('var(')[1]?.split(')')[0] || '')
          .split('px')[0],
      )
    : 0;
};
